import React from "react"

import Layout from "../templates/basic/";
import Intro from 'components/intro'
import SEO from '../components/seo';
// import SectionCallout from '../components/sectioncallout/';

const StyleGuide = ({ pageContext }) => {
	return (
		<>
			<Layout preTitle="Welcome to" title="The Style Guide">
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							<section className="main-content">
								<h1>Header 1</h1>
								<h2>Header 2</h2>
								<h3>Header 3</h3>
								<h4>Header 4</h4>
								<h5>Header 5</h5>
								<h6>Header 6</h6>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum libero non turpis vulputate, lacinia scelerisque dolor lobortis. Fusce purus urna, porttitor id ultricies vel, feugiat sed sem. In in lobortis elit. Curabitur vel volutpat enim, eu auctor elit. Mauris suscipit varius nulla, commodo suscipit dui ultricies eget. Vestibulum at interdum nisi. Fusce non velit eu augue aliquam cursus at et lacus.</p>
								<p>Aenean faucibus aliquet volutpat. Sed egestas pretium quam at lobortis. Maecenas lobortis dignissim arcu et luctus. Sed feugiat rhoncus orci, dictum blandit neque eleifend eu. Praesent cursus tortor vel magna tincidunt facilisis. Duis vel ipsum eget sem pretium pellentesque. Nunc a laoreet erat.</p>
								<p>Pellentesque volutpat ornare accumsan. Aenean nibh turpis, accumsan at risus at, condimentum maximus enim. Morbi hendrerit quam in turpis egestas, blandit consectetur mi dictum. Nullam ligula ligula, imperdiet non scelerisque in, vulputate feugiat nulla. Nullam eros ipsum, interdum eu cursus sit amet, sodales sit amet eros. Nam bibendum libero et leo posuere, eu aliquet leo facilisis. In at malesuada turpis. Sed nisl ligula, elementum eget tellus et, eleifend vulputate purus. Suspendisse potenti. Nunc viverra augue vel odio ultrices elementum. In nisi mi, congue ut efficitur quis, porta sit amet mi. Vestibulum id orci congue, imperdiet libero ut, molestie tellus.</p>
								<div style={{ background: 'lightgray' }}>
									<p><a href="/" class="button">Standard Button</a></p>
									<p><a href="/" class="button outline">Outline Button</a></p>
									<p><a href="/" class="button outline-white">Outline-White Button</a></p>
									<p><a style={{ margin: 0 }} href="/" class="button small">Smaller Button</a></p>
								</div>
							</section>
						</div>
					</div>
				</div>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							<hr style={{ margin: "5rem auto" }} />
						</div>
					</div>
				</div>
				<Intro pullQuote="This section is for important pull quote or stand out information to be captured here" headline="Page Section Headline" pageCopy="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
			</Layout>
			<SEO title="Danos Style Guide" />
		</>
	)
}

export default StyleGuide